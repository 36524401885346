import React from 'react';
import {Link} from "react-router-dom"
import LOGO from "../../assets/images/orangelogo.png";

const logo = () => {
  return (
    <>
    
    <div style={{backgroundColor:"black", zIndex:"99999999999" ,display:"flex" ,borderBottom:"1px solid #fff", justifyContent:"center", height:"60px", width:"100%" ,alignItems:"center"}} >
     
        
           <img height={"25px"}  src={LOGO} alt="Company logo" /> 
          
           
    </div>
     
    </>
  )
}

export default logo
