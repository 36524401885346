import React, { Suspense, lazy, useLayoutEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import Logo from "./components/logo/logocomp";
import ScrollToTop from './components/scroll/ScrollToTop';
const Home = lazy(() => import("./pages/home/home"));
const Work = lazy(() => import("./pages/work/work"));
const Service = lazy(() => import("./pages/services/service"));
const Footer = lazy(() => import("./components/footer/footer"));
const Faq = lazy(() => import("./components/faqs/faq"));
const FaqM = lazy(() => import("./components/faqs/mobilefaq"));
const  Contact = lazy(() => import("./pages/contact/contact"))
const Career = lazy(() => import("./pages/careers/career") );

const App = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 800);

  useLayoutEffect(() => {
    function updateScreenWidth() {
      setIsWideScreen(window.innerWidth > 800);
    }
    window.addEventListener('resize', updateScreenWidth);
    return () => window.removeEventListener('resize', updateScreenWidth);
  }, []);

  return (
    <>
      <Logo />
      <BrowserRouter>
      <ScrollToTop />
        <Suspense 
          fallback={
            <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100vh", alignItems: "center" }}>
              <BounceLoader with="200px" height="200px" color="#B83700" /> 
            </div>
          }
        >
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/services" element={<Service />} />
            <Route path="/workwithus" element={<Work />} />
            <Route path="/Faq" element={isWideScreen ? <Faq /> : <FaqM />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/careers" element={<Career />} />
            <Route path="*" element={<Navigate to="/" />} />
           </Routes>
        </Suspense>
        <Footer />
      </BrowserRouter>
    </>
  )
}

export default App;
